/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';

export default class BalanceReplenishmentByWalletPage extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Getter('getEWallet', {namespace}) getEWallet: any;
	@Mutation('setTypeWallet', {namespace}) setTypeWallet: any;

	handleTypeWallet: string = '';

	handleBillFormed(type: string) {
		this.setTypeWallet(type);
		this.setTypeWallet(this.handleTypeWallet);
		this.$router.push({name: 'bill-formed'}).then(r => r);
	}
}
